import axios from "axios";
// import router from "@/router/index";
// import Vue from "vue";

const instance = axios.create({});

// Declarando rota default
instance.defaults.baseURL = process.env.VUE_APP_URL;

// Interceptando requisição
instance.interceptors.request.use((config) => {
  const token = localStorage.getItem("userToken");
  config.headers.common["Authorization"] = `Bearer ${token}`;
  return config;
});

// Interceptando resposta
instance.interceptors.response.use(

  // (config) => {
  //   console.log("CONFIG AUTHORIZATION:", config.headers.authorization);
  //   localStorage.setItem("userToken", config.headers.authorization);
  //   return config;
  // },

  (config) => {
    if((config.headers.authorization) || !(localStorage.getItem("userToken"))){
    localStorage.setItem("userToken", config.headers.authorization);
      return config;
    }else{
      return config;
    }
  },

  (error) => {
    if (error.response.data.error == "Unauthenticated.") {
      alert("Você não está autenticado!");
      window.location.href = "/";
    }

    if (error.response.status >= 400) {
      if (typeof error.response.data.errors != "string") {
        Object.values(error.response.data.errors).map((value) => {
          throw value.toString();
        });
      } else {
        throw error.response.data.errors;
      }
    }
  }
);

export default instance;
