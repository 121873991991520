export default {
    state: {
      responsiblesData: {},
    },
  
    getters: {
      getResponsiblesData(state) {
        return state.responsiblesData;
      },
    },
  
    mutations: {
       setResponsiblesData(state, data) {
        const isNull = data === null;

        state.responsiblesData = {
          id: !isNull ? data.id : "",
          cnes: !isNull ? data.cnes : "",
          name: !isNull ? data.name : "",
          corporateName: !isNull ? data.corporateName : "",
          clinicalDirector: !isNull ? data.clinicalDirector : "",
          cep: !isNull ? data.cep : "",
          adress: !isNull ? data.adress : "",
          number: !isNull ? data.number : "",
          district: !isNull ? data.district : "",
          municipality: !isNull ? data.municipality : "",
          complement: !isNull ? data.complement : "",
          health_regions: !isNull ? data.health_regions : "",
          macroregion: !isNull ? data.macroregion : "",
          uf: !isNull ? data.uf : "",
          telephone: !isNull ? data.telephone : "",
          alternative_telephone: !isNull ? data.alternative_telephone : "",
          cellphone: !isNull ? data.cellphone : "",
          email: !isNull ? data.email : ""
        };
      },
    },
  
    actions: {
    changeResponsiblesData(context, infos) {
        context.commit("setResponsiblesData", infos);
      },
    },
  };
  