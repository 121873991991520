import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import VueMask from "v-mask";
import "./plugins/vuetify-money";
import { debounce } from "lodash";
import "./assets/styles/_main.scss";

Vue.use(VueMask);
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  debounce,
  render: (h) => h(App),
}).$mount("#app");
