export default {
    state: {
      itemsData: {},
    },
  
    item: {
      itemsData: {},
    },

    getters: {
      getItemsData(state) {
        return state.itemsData;
      },

      getUpdateDate(state){
        return state.itemsData;
      }
    },
  
    mutations: {
      setNewItemsData(item, data) {
        const isNull = data === null;
        item.itemsData = {
          id: !isNull ? data.id : "",
          code: !isNull ? data.code : "",
          description: !isNull ? data.description : "",
          value: !isNull ? data.value : "",
          isEdit: !isNull ? data.isEdit : "",
          delivered: !isNull ? data.delivered : "",
          deliveredDescription: !isNull ? data.deliveredDescription : "",
        };
      },

      setItemsData(state, data) {
        const isNull = data === null;
        state.itemsData = {
          id: !isNull ? data.id : "",
          code: !isNull ? data.code : "",
          description: !isNull ? data.description : "",
          value: !isNull ? data.value : "",
          delivered: !isNull ? data.delivered : "",
          deliveredDescription: !isNull ? data.deliveredDescription : "",
        };
      }
      
    },
  
    actions: {
      changeItemsData(context, data){
        context.commit("setItemsData", data);
      },

      updateItemData(context, data){
        context.commit("setNewItemsData", data)
      }
    },
  };
  