import CryptoJS from "crypto-js";

const settingDecryption = (name, password) => {
  if (localStorage.getItem(name) != null) {
   
    const encryptedData = localStorage.getItem(name);
    const decryptingData = CryptoJS.AES.decrypt(encryptedData, password);
    const decryptedData = JSON.parse(
      decryptingData.toString(CryptoJS.enc.Utf8)
    );

    return decryptedData;
  }
};

export default settingDecryption;
