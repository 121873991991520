import store from "@/store/index";
import getCurrentDateAndTime from "@/utils/setDateAndHourInformations";
import decryptData from "@/utils/removeEncryption";
import setDateAndHour from "@/utils/setDateAndHourInformations";
import settingEncryption from "@/utils/addEncryption";
import axios from "@/axios/index.js";

const validatePermissions = (toRoute) => {
  const userData = decryptData("userData", "@qG56W5w04YI");
  const userDataTime = decryptData("userDataTime", "9gX5#v@93%z1");

  if (userData != undefined && userDataTime != undefined) {
    const currentDateAndTime = getCurrentDateAndTime();
    const differenceBetweenTimes =
      currentDateAndTime.hourConvertedToMinutes -
      userDataTime.hourConvertedToMinutes;

    if (
      userDataTime.formattedDate == currentDateAndTime.formattedDate &&
      differenceBetweenTimes < 600
    ) {
      let routeInfos = {};

      store.state.localRoutes.forEach((route) => {
        if (route.children !== undefined) {
          route.children.forEach((childrenRoute) => {
            if (toRoute.name == childrenRoute.name) routeInfos = childrenRoute;
          });
        }
      });
      
      return routeInfos.permission === "dashboard" ||
        routeInfos.permission === "account"
        ? "needReload"
        : userData.data.abilities.some((a) => a === routeInfos.permission);
    } else {
      return "needReload";
    }
  } else {
    return false;
  }
};

const validationRoute = (to, from, next) => {
  const status = validatePermissions(to);
  if (status === true) {
    next();
  } else if (status === "needReload") {
    axios
      .get(`/auth/me`)
      .then((response) => {
        const currentDateTime = setDateAndHour();
        settingEncryption(
          "userData",
          JSON.stringify(response.data),
          "@qG56W5w04YI"
        );
        settingEncryption(
          "userDataTime",
          JSON.stringify(currentDateTime),
          "9gX5#v@93%z1"
        );
        next();
      })
      .catch(() => {
        alert("Realize o login para acessar está página!");
        next("/");
      });
  } else {
    alert("Você não tem permissão para acessar esse recurso!");
    next(false);
  }
};

export default { validationRoute };
