import Vue from "vue";
import Vuex from "vuex";

import notifications from "./modules/widgets/notifications";
import grids from "./modules/widgets/grids";
import modals from "./modules/widgets/modals";

import routes from "./modules/routesModule";
import captcha from "./modules/captchaModule";
import password from "./modules/passwordModule";
import account from "./modules/accountModule";
import user from "./modules/userModule";
import profiles from "./modules/profilesModule";
import items from "./modules/itemsModule";
import lawsuits from "./modules/lawsuitsModule";
import responsibles from "./modules/responsiblesModule";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    // WIDGETS
    notifications,
    grids,
    modals,

    // MODULES
    routes,
    captcha,
    password,
    account,
    user,
    items,
    profiles,
    lawsuits,
    responsibles,
  },
});
