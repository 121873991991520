export default {
  state: {
    userData: {},
  },

  getters: {
    getUserData(state) {
      return state.userData;
    },
  },

  mutations: {
    setUserData(state, data) {
      const isNull = data === null;
      state.userData = {
        id: !isNull ? data.id : "",
        name: !isNull ? data.name : "",
        social_name: !isNull ? data.socialName : "",
        email: !isNull ? data.email : "",
        cpf: !isNull ? data.cpf : "",
        registration: !isNull ? data.registrationNumber : "",
        unitId: !isNull ? data.unitId : "",
        mainPhone: !isNull ? data.mainPhone : "",
        alternatePhone: !isNull ? data.alternatePhone : "",
        cellphone: !isNull ? data.cellphone : "",
        roleId: !isNull ? data.profile.id : "",
      };
    },
  },

  actions: {
    changeUserData(context, infos) {
      context.commit("setUserData", infos);
    },
  },
};
