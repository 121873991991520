export default {
  state: {
    defaultModalData: {
      data: null,
      type: "CREATE",
      status: false,
      route: "",
      componentName: "",
    },
  },

  getters: {
    getDefaultModalData(state) {
      return state.defaultModalData;
    },
  },

  mutations: {
    setDefaultModalInfos(state, infos) {
      state.defaultModalData.data = infos.data;
      state.defaultModalData.type = infos.data === null ? "CREATE" : "EDIT";
      state.defaultModalData.status = infos.status;
      state.defaultModalData.componentName = infos.componentName;
    },
  },

  actions: {
    changeDefaultModalInfos(context, infos) {
      context.commit("setDefaultModalInfos", infos);
    },
  },
};
