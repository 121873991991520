export default {
  state: {
    searchData: {
      type: "",
      search: "",
    },

    gridFilter: {},

    actionsData: [],

    items: [],
    
    updateGrid: false,
  },

  getters: {
    getSearchData(state) {
      return state.searchData;
    },

    getGridFilter(state) {
      return state.gridFilter;
    },
  
    getActionsData(state) {
      return state.actionsData;
    },

    getUpdateGrid(state) {
      return state.updateGrid;
    },

    getSelectedItems(state){
      return state.items;
    }
  },

  mutations: {
    setSearchData(state, data) {
      state.searchData = {
        type: data.type,
        search: data.search,
      };
    },

    setGridFilters(state, filter) {
      state.gridFilter = filter;
    },

    setActionsData(state, data) {
      state.actionsData = data;
    },

    setUpdateGridStatus(state, status) {
      state.updateGrid = status;
    },

    setUpdateGridItems(state, data) {
      state.items.push(data);
    },
  },

  actions: {
    changeSearchData(context, data) {
      context.commit("setSearchData", data);
    },

    changeGridFilters(context, data) {
      context.commit("setGridFilters", data);
    },

    changeActionsData(context, data) {
      context.commit("setActionsData", data);
    },

    changeUpdateGridStatus(context, status) {
      context.commit("setUpdateGridStatus", status);
    },

    changeGridSelectItems(context, data){
      context.commit("setUpdateGridItems", data)
    }
  },
};
