import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store/index";
import guard from "@/guard/routeGuard.js";
const { validationRoute } = guard;

Vue.use(VueRouter);

const routes = [
  // LOGIN
  {
    path: "/",
    component: () => import("@/views/ViewWithoutLayout.vue"),
    children: [
      {
        path: "",
        name: "Login",
        component: () => import("@/components/modules/login/Login.vue"),
      },
    ],
  },

  // DASHBOARD
  {
    path: "/dashboard",
    beforeEnter: validationRoute,
    component: () => import("@/views/ViewWithLayout.vue"),
    children: [
      {
        path: "",
        name: "Dashboard",
        permission: "dashboard",
        component: () => import("@/components/modules/dashboard/Grid.vue"),
      },
    ],
  },

  // CAD. BÁSICOS
  // PERFIS
  {
    path: "/perfis",
    beforeEnter: validationRoute,
    component: () => import("@/views/ViewWithLayout.vue"),
    children: [
      {
        path: "",
        name: "Gerenciamento de Perfis",
        permission: "list_profile",
        component: () => import("@/components/modules/profile/Grid.vue"),
      },
    ],
  },

  {
    path: "/permissoes",
    beforeEnter: validationRoute,
    component: () => import("@/views/ViewWithLayout.vue"),
    children: [
      {
        path: "",
        name: "Gerenciamento de Perfis",
        permission: "list_profile",
        component: () => import("@/components/modules/profile/Abilities.vue"),
      },
    ],
  },

  // USUARIOS
  {
    path: "/usuarios",
    beforeEnter: validationRoute,
    component: () => import("@/views/ViewWithLayout.vue"),
    children: [
      {
        path: "",
        name: "Gerenciar usuários",
        permission: "list_user",
        component: () => import("@/components/modules/users/Grid.vue"),
      },
    ],
  },

  // MINHA CONTA
  {
    path: "/minha-conta",
    beforeEnter: validationRoute,
    component: () => import("@/views/ViewWithLayout.vue"),
    children: [
      {
        path: "",
        name: "Minha Conta",
        permission: "account",
        component: () => import("@/components/modules/my-account/Account.vue"),
      },
    ],
  },

  // RESETAR SENHA
  {
    path: "/esqueci-minha-senha",
    component: () => import("@/views/ViewWithoutLayout.vue"),
    children: [
      {
        path: "",
        name: "Esqueci minha senha",
        component: () => import("@/components/modules/password/Password.vue"),
      },
    ],
  },

  // PLEITOS
  {
    path: "/pleitos",
    beforeEnter: validationRoute,
    component: () => import("@/views/ViewWithLayout.vue"),
    children: [
      {
        path: "",
        permission: "list_lawsuits",
        name: "Pleitos da Saúde",
        component: () => import("@/components/modules/lawsuits/Grid.vue"),
      },
    ],
  }, 

  {
    path: "/novo_pleito",
    beforeEnter: validationRoute,
    component: () => import("@/views/ViewWithLayout.vue"),
    children: [
      {
        path: "",
        permission: "cad_lawsuits",
        name: "Novo Pleito",
        component: () => import("@/components/modules/lawsuits/Lawsuits.vue"),
      },
    ],
  }, 

  // UNIDADES
  {
    path: "/unidades",
    beforeEnter: validationRoute,
    component: () => import("@/views/ViewWithLayout.vue"),
    children: [
      {
        path: "",
        permission: "list_unit",
        name: "Gerenciar Unidades",
        component: () => import("@/components/modules/units/Grid.vue"),
      },
    ],
  }, 

  {
    path: "/nova_unidade",
    beforeEnter: validationRoute,
    component: () => import("@/views/ViewWithLayout.vue"),
    children: [
      {
        path: "",
        permission: "cad_unit",
        name: "Nova Unidade",
        component: () => import("@/components/modules/units/Units.vue"),
      },
    ],
  }, 

  // RESPONSÁVEIS
  {
    path: "/responsaveis",
    beforeEnter: validationRoute,
    component: () => import("@/views/ViewWithLayout.vue"),
    children: [
      {
        path: "",
        permission: "list_responsible",
        name: "Responsáveis",
        component: () => import("@/components/modules/responsible/Grid.vue"),
      },
    ],
  },

  {
    path: "/novo_responsavel",
    beforeEnter: validationRoute,
    component: () => import("@/views/ViewWithLayout.vue"),
    children: [
      {
        path: "",
        permission: "cad_responsible",
        name: "Novo Responsável",
        component: () => import("@/components/modules/responsible/Responsible.vue"),
      },
    ],
  },
];

store.state.localRoutes = routes;

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
